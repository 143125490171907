.content {
    display: flex;
    flex-direction: column;
    width: 90%;
    height: 100vh;
    font-size: 22px;
    padding: 64px;
    margin-top: 64px;
}

.content-location {
    font-size: 16px;
}

.title {
    font-size: 40px;
}