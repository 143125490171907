.header {
    height: 64px;
    width: 100%;
    font-size: 18px;
    position: fixed;
    overflow: hidden;
    top: 0;
    background: white;
    box-shadow: rgba(160, 166, 170, 0.2) 0px 1px 5px 0px
}

.header-list {
    display: flex;
    align-items: center;
    margin: 6px;
}

.header-list li {
    display: block;
    align-items: center;
    list-style: none;
    margin: 0 1rem;
}

.header-list a {
    display: block;
    text-decoration: none;
    color: black;
    padding: 9px;
    transition: 0.3s;
    border-radius: 6px;
}

.header-list a:hover {
    text-decoration: underline;
    background-color: rgb(242, 234, 219);
}